import React, { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import FormCheck, { FormCheckProps } from '..';

interface FormikFormCheckProps extends FormCheckProps {
    name: string;
}

const FormikFormCheck: FC<FormikFormCheckProps> = (props) => {
    const [field] = useField(props);
    const { setFieldValue } = useFormikContext();

    const customOnChange = () => {
        setFieldValue(props.name, !field.value);
    };

    return <FormCheck checked={field.value} onChange={customOnChange} {...props} />;
};

export default FormikFormCheck;
