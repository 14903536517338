import React, { CSSProperties, FC } from 'react';
import { StyledFooterLink } from '../styles';

export interface FooterLinkProps {
    to: string;
    center?: boolean;
    style: CSSProperties;
}

const FooterLink: FC<FooterLinkProps> = ({ children, center, style, to }) => {
    return (
        <div style={{ textAlign: center ? 'center' : 'initial', ...style }}>
            <StyledFooterLink to={to}>
                {children}
            </StyledFooterLink>
        </div>
    );
};

export default FooterLink;
