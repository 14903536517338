import { Role } from 'utils/constants';

export const defaultAreaRoute = (role?: Role) => {
    switch (role) {
        case Role.Admin:
            return '/admin';
        case Role.RegularUser:
            return '/user';
        default:
            return '/';
    }
};
