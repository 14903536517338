import styled from '@emotion/styled';
import { ToggleButton } from 'react-bootstrap';

export const Wrapper = styled.div<{ expanded: boolean }>`
    width: 100%;
    height: ${props => props.expanded ? '100%' : 'auto'};
    padding: ${props => props.expanded ? '0' : '1.5rem'};
    border-radius: 2rem;
    background-color: ${props => props.expanded ? 'transparent' : 'white'};
`;

export const GraphWrapper = styled.div<{ expanded: boolean }>`
    height: ${props => props.expanded ? '33rem' : '15rem'};
    position: relative;
`;

export const Title = styled.div`
    font-size: 1.1rem;
    align: center;
    color: grey;
    transform: translateY(15%);
`;

export const ExpandButton = styled.button`
    height: 2.3rem;
    width: 2.3rem;
    border: 1px solid lightgrey;
    border-radius: 0.5rem;
    background-color: white;
    float: right;
    margin-left: 0;
`;

export const PeriodTypeToggle = styled(ToggleButton)`
    font-size: 0.8rem;

    border-radius: 1rem;
    border: 0;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    
    margin-right: 0.5rem;

    :last-child {
        margin-right: 0;
    }

    &.btn-outline-orange {
        border: 0;
    }
`;

export const Header = styled.div`
    float: right;
    margin-left: 0;
    display: flex;
`;

export const PeriodTypeWrapper = styled.div`
    border: 1px solid lightgrey;
    border-radius: 1.2rem;
    padding: 0.2rem;
    margin-right: 1rem;
`;
