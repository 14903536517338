import { FC, useEffect } from 'react';
import { Chart as ChartJS, Title, Tooltip, Legend, ChartData, ChartOptions, LinearScale, CategoryScale, PointElement, LineElement, ScriptableLineSegmentContext, ScriptableContext } from 'chart.js';
import { Col, Row } from 'react-bootstrap';
import Icon from 'components/atoms/Icon';
import { Line } from 'react-chartjs-2';
import { useOutletContext } from 'react-router';
import { OutletContext } from 'components/organisms/Layout/types';
import ExpandedComponent from 'components/atoms/expandedComponent';
import { ExpandButton, GraphWrapper, Header, PeriodTypeToggle, PeriodTypeWrapper, Title as TitleWrapper, Wrapper } from '../styles';
import { PeriodType } from 'hooks/api/graph/types';
import { useTranslation } from 'react-i18next';
import { asPrice } from 'utils/priceFormatter';
import { asPercentage } from 'utils/percentageFormatter';
import { colors } from 'theme/colors';
import { useSession } from 'contexts/sessionContext';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

interface LineGraphProps {
    title: string;
    labels: LabelModel[];
    datasets: LineGraphDataset[];
    periodType: PeriodType;
    setPeriodType: (periodType: PeriodType) => void;
    expanded?: boolean;
    close?: () => void;
    isLoading: boolean;
    type: 'currency' | 'percentage';
}

interface LabelModel {
    label: string;
    abbreviation?: string;
}

export interface LineGraphDataset {
    label: string;
    data: number[];
    color: string
}

const LineGraph: FC<LineGraphProps> = (props) => {
    const {
        expanded,
        title,
        labels,
        datasets,
        periodType,
        setPeriodType,
        close,
        isLoading,
        type
    } = props;

    const { t } = useTranslation('lineGraph');
    const { session } = useSession();
    const outletContext = useOutletContext<OutletContext>();

    useEffect(() => {
        if (expanded == null || !expanded) {
            outletContext.setPopups([{
                name: `expand-${title}`,
                element: (close) => (
                    <ExpandedComponent close={close}>
                        <LineGraph close={close} expanded={true} {...props} />
                    </ExpandedComponent>
                )
            }]);
        }
    }, [periodType, isLoading, session]);

    if (labels.length === 0 || isLoading) {
        return (
            <Wrapper expanded={expanded ?? false}>
                <Row>
                    <Col md={9}>
                        <TitleWrapper>
                            {title}
                        </TitleWrapper>
                    </Col>
                </Row>
                <Row style={{ padding: '1rem' }}>
                    {t(isLoading ? 'common:loading' : 'noData').toString()}
                </Row>
            </Wrapper>
        );
    }

    const positiveOrNegativeLine = (ctx: ScriptableContext<'line'>, positiveColor: string, negativeColor: string) => ctx.parsed.y >= 0 ? positiveColor : negativeColor;
    const positiveOrNegativeSegment = (ctx: ScriptableLineSegmentContext, positiveColor: string, negativeColor: string) => ctx.p0.parsed.y + ctx.p1.parsed.y >= 0 ? positiveColor : negativeColor;

    const data: ChartData<'line', number[], string> = {
        labels: labels.map(l => l.label),
        datasets: datasets.map(d => ({
            label: d.label,
            data: d.data,

            borderColor: d.color,
            backgroundColor: d.color,
            segment: type === 'percentage' ? {
                borderColor: ctx => positiveOrNegativeSegment(ctx, colors.positive, colors.negative),
            } : undefined,
            pointBackgroundColor: type === 'percentage'
                ? ctx => positiveOrNegativeLine(ctx, colors.positive, colors.negative)
                : undefined,
            pointBorderColor: type === 'percentage'
                ? ctx => positiveOrNegativeLine(ctx, colors.positive, colors.negative)
                : undefined,
        })),
    };

    const options: ChartOptions<'line'> = {
        responsive: true,
        elements: {
            point: {
                radius: 0
            }
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: type !== 'percentage',
                align: 'end',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded'
                }
            },
            tooltip: {
                callbacks: {
                    label: (t) => {
                        return type === 'currency'
                            ? asPrice(t.raw as number, 2)
                            : asPercentage(t.raw as number, 2);
                    }
                },
                backgroundColor: 'white',
                borderWidth: 1,
                borderColor: 'lightgrey',
                bodyColor: 'black',
                titleColor: 'black'
            },
        },
        scales: {
            x: {
                ticks: {
                    callback: (_, index) => labels[index].abbreviation
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: (value) => type === 'currency'
                        ? asPrice(value as number, 0, true)
                        : asPercentage(value as number, 0, true)
                }
            }
        },
        maintainAspectRatio: false
    };

    const periodTypes = Object.values(PeriodType).filter(v => typeof v === 'number');

    return (
        <Wrapper expanded={expanded ?? false}>
            <Row>
                <Col md={5}>
                    <TitleWrapper>
                        {title}
                    </TitleWrapper>
                </Col>
                <Col md={7}>
                    <Header>
                        <PeriodTypeWrapper>
                            {periodTypes.map((p) => (
                                <PeriodTypeToggle
                                    key={p}
                                    id={`radio-${title}-${p}`}
                                    type="radio"
                                    variant="outline-orange"
                                    value={p}
                                    checked={periodType === p}
                                    onChange={(e) => setPeriodType(parseInt(e.currentTarget.value))}
                                >
                                    {t(`periodType.${p}`)}
                                </PeriodTypeToggle>
                            ))}
                        </PeriodTypeWrapper>
                        {expanded && close != null ?
                            <ExpandButton onClick={close}>
                                <Icon name="collapse" />
                            </ExpandButton> :
                            <ExpandButton onClick={() => outletContext.openPopup(`expand-${title}`)}>
                                <Icon name="expand" />
                            </ExpandButton>
                        }
                    </Header>
                </Col>
            </Row>
            <Row style={{ marginTop: '1rem' }}>
                <GraphWrapper expanded={expanded ?? false}>
                    <Line data={data} options={options} />
                </GraphWrapper>
            </Row>
        </Wrapper>
    );
};

export default LineGraph;
