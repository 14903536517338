import { useQuery } from 'react-query';
import { CustomError, useApi } from '..';
import { CircleGraphCostModel, CircleGraphValueModel, PeriodType, ProfitLossRangeModel, ValueCostRangeModel } from './types';

const useGraph = () => {
    const { apiGet } = useApi();

    const baseUrl = '/Graph';

    const circleGraphValue = async () => {
        const response = await apiGet<CircleGraphValueModel>(`${baseUrl}/circleValue`);
        return response;
    };

    const useCircleGraphValue = () => {
        const queryKey = ['graph.circleValue'];

        return useQuery<CircleGraphValueModel | null, CustomError[]>(queryKey, async () => {
            const response = await circleGraphValue();

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? null;
        });
    };

    const circleGraphCost = async () => {
        const response = await apiGet<CircleGraphCostModel>(`${baseUrl}/circleCost`);
        return response;
    };

    const useCircleGraphCost = () => {
        const queryKey = ['graph.circleCost'];

        return useQuery<CircleGraphCostModel | null, CustomError[]>(queryKey, async () => {
            const response = await circleGraphCost();

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? null;
        });
    };

    const lineGraphValueCost = async (periodType: PeriodType) => {
        const periodTypeString = periodType.toString();
        const response = await apiGet<ValueCostRangeModel[]>(`${baseUrl}/lineValueCost/${periodTypeString}`);
        return response;
    };

    const useLineGraphValueCost = (periodType: PeriodType) => {
        const queryKey = ['graph.lineValueCost', periodType];

        return useQuery(queryKey, async () => {
            const response = await lineGraphValueCost(periodType);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const lineGraphProfitLoss = async (periodType: PeriodType) => {
        const periodTypeString = periodType.toString();
        const response = await apiGet<ProfitLossRangeModel[]>(`${baseUrl}/lineProfitLoss/${periodTypeString}`);
        return response;
    };

    const useLineGraphProfitLoss = (periodType: PeriodType) => {
        const queryKey = ['graph.lineProfitLoss', periodType];

        return useQuery(queryKey, async () => {
            const response = await lineGraphProfitLoss(periodType);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    return {
        useCircleGraphValue,
        useCircleGraphCost,
        useLineGraphValueCost,
        useLineGraphProfitLoss
    };
};

export default useGraph;



