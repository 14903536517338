//https://medium.com/eightshapes-llc/space-in-design-systems-188bcbae0d62 voor meer informatie

const inset = {
    xxs: '0.25rem',
    xs: '0.5rem',
    s: '0.75rem',
    m: '1rem',
    l: '1.5rem',
    xl: '2rem',
} as const;

const insetSquish = {
    s: '0.25rem 0.5rem',
    m: '0.5rem 1rem',
    l: '1rem 1.5rem',
} as const;

const insetStretch = {
    s: '0.75rem 0.5rem',
    m: '1.5rem 1rem',
    l: '1rem 2rem'
} as const;

const stack = {
    xxs: '0 0 0.25rem 0',
    xs: '0 0 0.5rem 0',
    s: '0 0 0.75rem 0',
    m: '0 0 1rem 0',
    l: '0 0 1.5rem 0',
    xl: '0 0 2rem 0',
    xxl: '0 0 4rem 0',
} as const;

const gap = {
    xxs: '0.25rem',
    xs: '0.5rem',
    s: '0.75rem',
    m: '1rem',
    l: '1.5rem',
    xl: '2rem',
    xxl: '4rem',
} as const;

export type Sizes = typeof inset | typeof insetSquish | typeof insetStretch | typeof stack | typeof gap;

const space = {
    inset,
    insetSquish,
    insetStretch,
    stack,
    gap
};

export default space;
