export const asPrice = (price: number, decimals: number, roundThousands?: boolean) => {
    if (roundThousands === true && price >= 1000) {
        return `€${Math.floor(price / 1000).toString()}k`;
    }

    return `€${price.toLocaleString('nl-NL', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    })}`;
};
