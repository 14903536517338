import Icon from 'components/atoms/Icon';
import { FC } from 'react';
import { IconWrapper } from './styles';
import { colors } from 'theme/colors';

export interface PercentageProps {
    percentage: number;
    icon?: boolean;
    iconSize?: number;
}

export const Percentage: FC<PercentageProps> = ({ percentage, icon, iconSize }) => {
    if (isNaN(percentage)) {
        return (
            <span>-</span>
        );
    }

    const percentageString = percentage.toLocaleString('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    if (percentage === 0) {
        return (
            <span>
                {percentageString}%
            </span>
        );
    }

    if (percentage >= 0) {
        const color = colors.positive;

        return (
            <span style={{ color: color }}>
                {icon
                    ? (
                        <IconWrapper>
                            <Icon
                                name="arrow-circle-right-up"
                                size={1}
                                color={color}
                            />
                        </IconWrapper>
                    )
                    : '+'
                }
                {percentageString}%
            </span>
        );
    }

    const color = colors.negative;

    return (
        <span style={{ color: color }}>
            {icon && (
                <IconWrapper>
                    <Icon
                        name="arrow-circle-right-down"
                        size={1}
                        color={color}
                    />
                </IconWrapper>
            )}
            {percentageString}%
        </span>
    );
};
