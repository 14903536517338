import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FormToggleProps } from '.';

export const ToggleContainer = styled.div`
  position: relative;
  display: flex;
  width: 1.6875rem;
  height: 1rem;
  background-color: ${({ theme }) => theme.colors.medium};
  border-radius: 3.125rem;
  overflow: hidden;

   div {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      transition: all .4s ease 0s;
      height: 100%;
      width: 100%;

        &:before {
            position: absolute;
            content: "";
            height: .8125rem;
            width: .8125rem;
            left: 1px;
            top: 50%;
            transform: translateY(-50%);
            background-color: ${({ theme }) => theme.colors.white};
            transition: all .4s ease 0s;
            border-radius: 3.125rem;
    }
  }
  `;

export const HiddenCheckbox = styled.input<FormToggleProps>`
  opacity: 0;
  width: 0;
  height: 0;
    &:checked {
        + div {
            background-color: ${({ theme }) => theme.colors.success};
            &:before {
                transform: translate(calc(100% - 1px), -50%);
            }
        }
  }
`;

export const Label = styled.span`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
    margin-left: ${({ theme }) => theme.space.inset.xs};
`;

export const Wrapper = styled.label<{ label?: string; disabled?: boolean; }>`
  display: ${({ label }) => (label ? 'flex' : 'inline-flex')};
  align-items: center;
  user-select: none;
  position: relative;
    ${({ disabled }) =>
        !disabled &&
        css`
            cursor: pointer;
        `
    }  
`;
