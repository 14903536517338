import { Field } from 'formik';
import React, { FC, InputHTMLAttributes } from 'react';
import { BigCheckbox, SmallCheckbox } from './styles';

export interface FormCheckProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    fontSize?: 'big' | 'small';
}

const FormCheck: FC<FormCheckProps> = ({ fontSize, ...inputProps }) => {
    return (
        <Field as={fontSize === 'big' ? BigCheckbox : SmallCheckbox} {...inputProps} />
    );
};

export default FormCheck;
