import styled from '@emotion/styled';
import { Card } from '../card';

export const PopupCard = styled(Card) <{ aspectratio: number }>`
    position: absolute;
    z-index: 20;
    width: calc(70vh / ${props => props.aspectratio});
    min-width: calc(700px / ${props => props.aspectratio});
    max-width: calc(40rem / ${props => props.aspectratio});
    height: 70vh;
    min-height: 700px;
    max-height: 40rem;
    overflow-y: auto;
`;

export const CloseButton = styled.button`
    border: 0;
    background-color: transparent;
`;
