import { CoinModel } from '../coin/types';

export interface CircleGraphValueModel {
    shownShares: ValueShareModel[];
    othersShareValue?: number;
    othersSharePercentage?: number;
}

export interface ValueShareModel {
    coin: CoinModel;
    value: number;
    percentage: number;
}

export interface CircleGraphCostModel {
    shownShares: CostShareModel[];
    othersShareCost?: number;
    othersSharePercentage?: number;
}

export interface CostShareModel {
    coin: CoinModel;
    cost: number;
    percentage: number;
}

export enum PeriodType {
    Month = 0,
    Quarter = 1,
    Year = 2
}

export interface ValueCostRangeModel {
    id: number;
    name: string;
    abbreviation?: string;
    value: number;
    cost: number;
}

export interface ProfitLossRangeModel {
    id: number;
    name: string;
    abbreviation?: string;
    profitLoss: number;
}
