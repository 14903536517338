import { FC } from 'react';
import { asPercentage } from 'utils/percentageFormatter';
import { Left, Right, Wrapper, TitlesWrapper, TitleWrapper, SubtitleWrapper } from './styles';

export interface TabItemProps {
    image: string;
    title: string,
    subtitle: string;
    percentage: number;
}

const TabItem: FC<TabItemProps> = ({ image, title, subtitle, percentage }) => {
    return (
        <>
            <Wrapper>
                <Left>
                    <img src={image} alt={title} width="36" height="36" />
                    <TitlesWrapper>
                        <TitleWrapper>
                            {title}
                        </TitleWrapper>
                        <SubtitleWrapper>
                            {subtitle}
                        </SubtitleWrapper>
                    </TitlesWrapper>
                </Left>
                <Right isPositive={percentage >= 0}>
                    {asPercentage(percentage, 2)}
                </Right>
            </Wrapper>
        </>
    );
};

export default TabItem;
