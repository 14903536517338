export const asPercentage = (percentage: number, decimals: number, roundThousands?: boolean) => {
    if (roundThousands === true && Math.abs(percentage) >= 1000) {
        return `${percentage > 0 ? '+' : ''}${Math.floor(percentage / 1000).toString()}k%`;
    }

    return `${percentage > 0 ? '+' : ''}${percentage.toLocaleString('nl-NL', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    })}%`;
};
