import styled from '@emotion/styled';

export const LoadingContainer = styled.div`
    position: absolute;
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    z-index: 20;
`;
