import { t } from 'i18next';
import { ButtonProps as BootstrapButtonProps } from 'react-bootstrap/esm/Button';
import { CustomButton } from './styles';

export interface BButtonProps extends BootstrapButtonProps {
    loading?: boolean;
}

const Button = (props: BButtonProps) => {
    const { loading, children, ...buttonProps } = props;
    return (
        <CustomButton disabled={loading} variant="orange" loading={loading?.toString()} {...buttonProps}>
            {loading ? t('common:loading') : children}
        </CustomButton>
    );
};

export default Button;
