import React from 'react';
import { useSession } from 'contexts/sessionContext';
import { useNavigate } from 'react-router-dom';
import { LoginInputModel, ResetTfaModel } from './types';
import { useApi } from 'hooks/api';
import { defaultAreaRoute } from 'utils/routehelper';
import { ForgotPasswordInputModel, ResetPasswordInputModel, SessionModel } from 'hooks/api/account/types';
import { TfaCompleteInputModel, TfaLoginResultModel } from 'hooks/api/types';

const useAccount = () => {
    const { setSession, clearSession } = useSession();
    const navigate = useNavigate();
    const { apiPost, apiPut } = useApi();

    const redirectToLogin = () => {
        navigate('/account');
    };

    const login = async (values: LoginInputModel) => {
        const response = await apiPost<SessionModel>(
            '/account/cookielogin', values
        );

        if (response.ok && response.data) {
            const data = response.data;

            const newSession = setSession(data.claims);
            const defaultRoute = defaultAreaRoute(newSession.role);
            navigate(defaultRoute);
        }

        return response;
    };

    const logOut = async () => {
        const response = await apiPost('/account/cookielogout');

        if (response.ok) {
            clearSession();
        }
    };

    const refreshSession = React.useCallback(async () => {
        const response = await apiPost<SessionModel>('/Account/cookierefresh');

        if (response.ok && response.data) {
            const { claims } = response.data;
            setSession(claims);
        } else {
            redirectToLogin();
        }
    }, [setSession]);

    const forgotPassword = async (model: ForgotPasswordInputModel) => {
        const response = await apiPost(
            '/account/forgotPassword', model
        );
        return response;
    };

    const resetPassword = async (model: ResetPasswordInputModel) => {
        const response = await apiPut(
            '/Account/resetPassword', model
        );
        return response;
    };

    const tfaLogin = async (values: LoginInputModel) => {
        const response = await apiPost<TfaLoginResultModel>(
            '/account/tfaLogin', values
        );

        if (response.ok && response.data) {
            const data = response.data;

            if (data.session) {
                const newSession = setSession(data.session.claims);
                const defaultRoute = defaultAreaRoute(newSession.role);
                navigate(defaultRoute);
            } else if (data.tfa) {
                navigate('/account/tfa', {
                    state: {
                        userName: data.tfa.userName,
                        secret: data.tfa.secret,
                        rememberMe: data.tfa.rememberMe
                    }
                });
            }
        }

        return response;
    };

    const tfaComplete = async (values: TfaCompleteInputModel) => {
        const response = await apiPost<SessionModel>(
            '/account/tfaComplete', values
        );

        if (response.ok && response.data) {
            const data = response.data;

            const newSession = setSession(data.claims);
            const defaultRoute = defaultAreaRoute(newSession.role);
            navigate(defaultRoute);
        }

        return response;
    };


    const resetTfa = async (model: ResetTfaModel) => {
        const response = await apiPost('/account/resetTfa', model);
        return response;
    };

    const loginWithUsername = async (username: string) => {
        const response = await apiPost<SessionModel>(`/account/cookieloginwithusername/${username}`);

        if (response.ok && response.data) {
            const data = response.data;

            const newSession = setSession(data.claims);
            const defaultRoute = defaultAreaRoute(newSession.role);
            navigate(defaultRoute);
        }

        return response;
    };

    return {
        login,
        logOut,
        refreshSession,
        forgotPassword,
        resetPassword,
        tfaLogin,
        tfaComplete,
        resetTfa,
        loginWithUsername
    };
};

export default useAccount;
