import navigation from './navigation';
import header from './header';
import greys from './greys';
import space from './space';
import { chartColors, colors } from './colors';

const theme = {
    colors,
    chartColors,
    greys,
    navigation,
    header,
    space
};

export default theme;
