import { StrictMode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from './AppRoutes';
import { SessionProvider } from 'contexts/sessionContext';
import { Global, css, ThemeProvider } from '@emotion/react';
import theme from './theme';
import 'i18n';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import nl from 'date-fns/locale/nl';
import { registerLocale } from 'react-datepicker';

const styles = css`
.btn-dark {
    background-color: rgb(224, 224, 224) !important;
    color: black !important;
    font-weight: normal !important;

    :hover {
        background-color: #eeeeee !important;
        color: black;
    }
}

::-webkit-scrollbar {
    height: 6px;
    width: 6px;

    &-thumb {
        border-radius: 5px;
        background: rgba(0,0,0, .3);

        &:hover {
            background: rgba(0,0,0, .5);
        }
    }
}

html, body, div#root {
  height: 100% !important;
}

.btn-sideBar {
    border-radius: 0.5rem;
    border-style: none;
    text-align: left;
    padding: 1.125rem;
    background-color: transparent;
    color: #C9C9C9;
    outline: none;
    box-shadow: none;

    svg * {
      fill: #C9C9C9;
    }

    .icon-wrapper {
      margin-right: 1rem;
    }

    :hover {
        background-color: white;
        outline: none;
        box-shadow: none;
        svg * {
          fill: #ffa500;
      }
    }
      
  :active {
    background-color: #ffa500;
    color: white;
    outline: none;
    box-shadow: none;
  }
  
  :focus, :focus-within {
    outline: none;
    box-shadow: none;
  }
}

.btn-check:checked+.btn-sideBar, .show>.btn-sideBar.dropdown-toggle {
  background-color: #F6F6F6;
  color: #282828;
  box-shadow: none;
  svg * {
    fill: #ffa500;
  }
}

.btn-outline-orange {
    border: 1px solid black;

    :active, :hover {
        background-color: #ffa500;
        color: white;
        outline: none;
        box-shadow: none;
        border: 1px solid #ffa500;
    }
}

.btn-check:checked+.btn-outline-orange {
    background-color: #ffa500;
    color: white;
    outline: none;
    box-shadow: none;
    border: 1px solid #ffa500;
}

.btn-orange {
    background-color: #ffa500 !important;

    :hover {
        color: white;
        background-color: #ffc04a !important;
    }
}

.form-check-input:checked {
    background-color: #ffa500;
    border-color: #ffa500;
    outline: none;
    box-shadow: none;
}

.form-switch .form-check-input {
    border-color: #ffa500;
    outline: none;
    box-shadow: none;
}

/* Manually import react-multi-email CSS styles to customize */
.react-multi-email {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 0.5em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 13px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}

.react-multi-email > span[data-placeholder] {
  display: none;
  position: absolute;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1.21428571em;
}

.react-multi-email.focused {
  border-color: #85b7d9;
  background: #fff;
}

.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: #ccc;
}
.react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.react-multi-email > input {
  width: 100%;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}

.react-multi-email [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}

.react-multi-email [data-tag]:first-child {
  margin-left: 0;
}

.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}
`;

const queryClient = new QueryClient();

registerLocale('nl', nl);

const App = () => {

  return (
    <BrowserRouter>
      <StrictMode>
        <Global styles={styles} />
        <ToastContainer />
        <ThemeProvider theme={theme}>
          <SessionProvider>
            <QueryClientProvider client={queryClient}>
              <HubspotProvider>
                <AppRoutes />
              </HubspotProvider>
            </QueryClientProvider>
          </SessionProvider>
        </ThemeProvider>
      </StrictMode>
    </BrowserRouter>
  );
};

export default App;
