import React, { FC } from 'react';
import { LoadingContainer } from './styles';
import { useTranslation } from 'react-i18next';

const LoadingScreen: FC = () => {
    const { t } = useTranslation('loading');

    return (
        <LoadingContainer>
            {t('loading')}
        </LoadingContainer>
    );
};

export default LoadingScreen;
