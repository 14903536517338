import { FC } from 'react';
import { StyledCard } from './styles';

export interface CardProps {
    variant?: 'small';
    zindex?: number;
}

const Card: FC<CardProps> = ({ variant, children, zindex, ...inputProps }) => {
    return (
        <StyledCard variant={variant} zindex={zindex} body {...inputProps}>
            <>
                {children}
            </>
        </StyledCard>
    );
};

export default Card;
