import Margin from 'components/atoms/margin';
import { PortfolioCard } from 'components/atoms/portfolioCard';
import { Section } from 'components/atoms/section';
import { TitleText } from 'components/atoms/text/styles';
import CircleGraphCost from 'components/organisms/graphs/CircleGraph/CircleGraphCost';
import CircleGraphValue from 'components/organisms/graphs/CircleGraph/CircleGraphValue';
import LineGraphProfitLoss from 'components/organisms/graphs/LineGraph/LineGraphProfitLoss';
import LineGraphValueCost from 'components/organisms/graphs/LineGraph/LineGraphValueCost';
import { OutletContext } from 'components/organisms/Layout/types';
import RisersFallers from 'components/organisms/RisersFallers';
import usePortfolio from 'hooks/api/portfolio';
import { FC, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router';
import { asPrice } from 'utils/priceFormatter';
import { DashboardWrapper, DashboardWrapperLeft, MaxWidthContainer } from './styles';
import { stats } from 'utils/statsHelper';
import { Percentage } from 'components/atoms/percentage';
import { PackageLimitPopup } from 'components/atoms/packageLimitPopup';
import { Right } from 'utils/constants';
import { useSession } from 'contexts/sessionContext';

const Dashboard: FC = () => {
    const { t } = useTranslation('userDashboard');
    const { setLayoutPath, setPopups, openPopup } = useOutletContext<OutletContext>();
    const { useBalances } = usePortfolio();
    const { hasRight } = useSession();
    const navigate = useNavigate();

    useEffect(() => {
        setLayoutPath('dashboard');
        setPopups([{
            name: 'dashboardAccessDenied',
            element: (close) => (
                <PackageLimitPopup
                    close={async () => {
                        close();
                        navigate('/user');
                    }}
                    text={t('dashboardLimit')}
                />
            )
        }]);
        if (!hasRight(Right.DashboardEnabled)) {
            openPopup('dashboardAccessDenied');
        }
    }, []);

    const { data: balances = [], isLoading } = useBalances();
    const {
        totalBalance,
        totalCosts,
        totalProfitLoss,
        totalProfitLossPercentage
    } = stats(balances);

    if (!hasRight(Right.DashboardEnabled)) {
        return <></>;
    }

    return (
        <DashboardWrapper>
            <DashboardWrapperLeft>
                <MaxWidthContainer>
                    <TitleText>
                        {t('dashboardTitle')}
                    </TitleText>
                    <Margin bottom={1}>
                        <Row className="g-3">
                            <Col md={3}>
                                <PortfolioCard
                                    label={t('totalBalance')}
                                    value={asPrice(totalBalance, 2)}
                                    isLoading={isLoading}
                                />
                            </Col>
                            <Col md={3}>
                                <PortfolioCard
                                    label={t('totalCosts')}
                                    value={asPrice(totalCosts, 2)}
                                    isLoading={isLoading}
                                />
                            </Col>
                            <Col md={3}>
                                <PortfolioCard
                                    label={t('profitLossEuro')}
                                    value={asPrice(totalProfitLoss, 2)}
                                    textColorBySign={totalProfitLoss}
                                    isLoading={isLoading}
                                />
                            </Col>
                            <Col md={3}>
                                <PortfolioCard
                                    label={t('profitLossPercentage')}
                                    value={<Percentage percentage={totalProfitLossPercentage} icon />}
                                    isLoading={isLoading}
                                />
                            </Col>
                        </Row>
                    </Margin>
                    <Margin bottom={1}>
                        <Row className="g-3">
                            <Col md={6}>
                                <CircleGraphValue />
                            </Col>
                            <Col md={6}>
                                <CircleGraphCost />
                            </Col>
                        </Row>
                    </Margin>
                    <Row className="g-3">
                        <Col md={6}>
                            <LineGraphValueCost />
                        </Col>
                        <Col md={6}>
                            <LineGraphProfitLoss />
                        </Col>
                    </Row>
                </MaxWidthContainer>
            </DashboardWrapperLeft>
            <Section bgColor="medium" maxWidth="20rem">
                <RisersFallers />
            </Section>
        </DashboardWrapper>
    );
};

export default Dashboard;
