import { FC } from 'react';
import { colors, Colors } from 'theme/colors';
import { StyledSection } from './styles';

interface SectionProps {
    bgColor?: keyof Colors;
    maxWidth?: string;
    minWidth?: string;
    scrollX?: boolean;
}

const Section: FC<SectionProps> = ({ children, bgColor = 'light', maxWidth, minWidth, scrollX }) => {
    return (
        <StyledSection style={{ backgroundColor: colors[bgColor], maxWidth, minWidth, overflowX: scrollX === true ? 'scroll' : undefined }}>
            {children}
        </StyledSection>
    );
};

export default Section;
