import styled from '@emotion/styled';

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;

    &:not(:last-child) {
        padding-bottom: .5rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    }
`;

export const Left = styled.div`
    display: flex;
    align-items: center;
    width: 50%;

    img {
        margin-right: .5rem;
    }
`;

export const Right = styled.div<{ isPositive: boolean }>`
    color: ${({ theme, isPositive }) => isPositive ? theme.colors.white : theme.colors.white};
    background-color: ${({ theme, isPositive }) => isPositive ? theme.colors.positive : theme.colors.negative};
    padding: .5rem .65rem;
    border-radius: .35rem;
    font-weight: 500;
    font-size: .75rem;
    line-height: 1.25;
`;

export const TitlesWrapper = styled.div`
  min-width: 0; 
`;

export const TitleWrapper = styled.h5`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
    font-size: .9rem;
`;

export const SubtitleWrapper = styled.h6`
    color: ${({ theme }) => theme.colors.grey};
    margin-bottom: 0;
    font-size: .75rem;
`;
