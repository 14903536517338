import styled from '@emotion/styled';
import { Col } from 'react-bootstrap';

export const Wrapper = styled.div<{ expanded: boolean }>`
    height: ${props => props.expanded ? '100%' : 'auto'};
    padding: ${props => props.expanded ? '0' : '1.5rem'};
    border-radius: 2rem;
    background-color: ${props => props.expanded ? 'transparent' : 'white'};
`;

export const CircleGraphWrapper = styled.div`
    height: 100%;
    position: relative;
`;

export const LegendItemWrapper = styled.div`
    display: flex;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
`;

export const LegendItemColor = styled.div<{ color: string }>`
    width: 0.5rem;
    height: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 1rem;
    background-color: ${props => props.color};
    margin-right: 0.5rem;
`;

export const LegendItemTitle = styled.div`
    font-weight: bold;
`;

export const LegendItemPercentage = styled.div`
    color: grey;
    margin-left: 0.5rem;
`;

export const InsideCircleWrapper = styled.div<{ expanded: boolean }>`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    max-width: ${props => props.expanded ? '15rem' : '9rem'};
    transform: translate(-50%, -50%);
`;

export const InsideCircleBlock = styled.div`
    border-radius: 0.5rem;
    border: 1px solid grey;
    padding: 0.5rem;
    background-color: white;
`;

export const InsideCircleTitleWrapper = styled.div`
    display: flex;
    color: grey;
`;

export const InsideCircleTitle = styled.div`
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
`;

export const InsideCirclePercentage = styled.div`
    font-weight: bold;
`;

export const LegendWrapper = styled(Col) <{ expanded?: boolean }>`
padding-right: 2rem;
    column-count: ${props => props.expanded ? 1 : 'auto'};
    column-width: 8rem;
`;
