import useGraph from 'hooks/api/graph';
import { PeriodType } from 'hooks/api/graph/types';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LineGraph, { LineGraphDataset } from '..';
import { colors } from 'theme/colors';

const LineGraphValueCost: FC = () => {
    const { t } = useTranslation('lineGraph');
    const [periodType, setPeriodType] = useState<PeriodType>(PeriodType.Month);

    const { useLineGraphValueCost } = useGraph();
    const { data = [], isLoading } = useLineGraphValueCost(periodType);

    const graphData = useMemo(() => {
        if (data == null) {
            return {
                labels: [],
                datasets: []
            };
        }

        const labels = data.map(m => ({ label: m.name, abbreviation: m.abbreviation }));
        const datasets: LineGraphDataset[] = [
            {
                label: t('label.lineGraphValue'),
                data: data.map(m => m.value),
                color: colors.positive
            },
            {
                label: t('label.lineGraphCost'),
                data: data.map(m => m.cost),
                color: colors.negative
            }
        ];

        return {
            labels,
            datasets
        };
    }, [data]);

    return (
        <LineGraph
            type="currency"
            title={t('label.lineGraphValueCost')}
            periodType={periodType}
            setPeriodType={setPeriodType}
            isLoading={isLoading}
            {...graphData}
        />
    );
};

export default LineGraphValueCost;
