import { FC, ReactNode, PropsWithChildren } from 'react';
import { Margin as Wrapper } from './styles';

export interface MarginProps {
    all?: number;
    horizontal?: number;
    vertical?: number;
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
    children?: ReactNode;
}

const Margin: FC<PropsWithChildren<MarginProps>> = ({ children, ...props }) => {
    return (<Wrapper {...props}>{children}</Wrapper>);
};

export default Margin;
