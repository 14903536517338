import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/atoms/Icon';
import { Card } from 'components/atoms/card';
import { TopBar, SelectWrapper, Title, TabSwitch, IconWrapper, NoData } from './styles';
import TabItem from './TabItem';
import useTrends from 'hooks/api/trends';
import { GainersLosersRange } from 'hooks/api/trends/types';

const RisersFallers: FC = () => {
    const { t } = useTranslation('risersFallers');
    const [activeTab, setActiveTab] = useState(0);
    const [rangeType, setRangeType] = useState(GainersLosersRange.Day);

    const { useGainersLosers } = useTrends();
    const { data = [], isLoading } = useGainersLosers({ range: rangeType, gainers: activeTab === 0 });

    const selectOptions = [
        { value: GainersLosersRange.Hour, label: t('rangeType.0') },
        { value: GainersLosersRange.Day, label: t('rangeType.1') },
        { value: GainersLosersRange.Week, label: t('rangeType.2') }
    ];

    const selectStyles = {
        control: (baseStyles: any) => ({
            ...baseStyles,
            border: 0,
            boxShadow: 'none'
        }),
        placeholder: (baseStyles: any) => {
            return {
                ...baseStyles,
                color: '#C9C9C9',
            };
        }
    };

    const selectComponents = {
        DropdownIndicator: () => (
            <Icon name="chevron-down" size={.6} />
        ),
        IndicatorSeparator: () => null,
    };

    return (
        <Card variant="small" zindex={0}>
            <TopBar>
                <Title>
                    {t('title')}
                </Title>
                <SelectWrapper
                    options={selectOptions}
                    styles={selectStyles}
                    isSearchable={false}
                    components={selectComponents}
                    value={selectOptions.find((option) => option.value === rangeType)}
                    onChange={(option: any) => setRangeType(option.value)}
                />
            </TopBar>
            <TabSwitch>
                <IconWrapper active={activeTab === 0} onClick={() => setActiveTab(0)}>
                    <Icon name="arrow-circle-right-up" size={1} />
                    {t('risers')}
                </IconWrapper>
                <IconWrapper active={activeTab === 1} onClick={() => setActiveTab(1)}>
                    <Icon name="arrow-circle-right-down" size={1} />
                    {t('fallers')}
                </IconWrapper>
            </TabSwitch>
            <div>
                {data.length > 0 ?
                    data.map((gl) => (
                        <TabItem
                            key={gl.coin.symbol}
                            image={gl.coin.logoUrl}
                            title={gl.coin.name}
                            subtitle={gl.coin.symbol}
                            percentage={gl.percentage}
                        />
                    ))
                    :
                    <NoData>
                        {isLoading ? t('common:loading') : t('noData')}
                    </NoData>
                }
            </div>
        </Card>
    );
};

export default RisersFallers;
