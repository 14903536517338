import { FC, ReactNode } from 'react';
import { CardTitle, CardSubtitle, StyledCard } from './styles';
import { colors } from 'theme/colors';

export interface PortfolioCardProps {
    label: string;
    value: ReactNode;
    percentage?: ReactNode;
    isLoading?: boolean;
    textColorBySign?: number;
}

const PortfolioCard: FC<PortfolioCardProps> = ({ label, value, percentage, isLoading, textColorBySign }) => {
    let textColor = colors.black;

    if (textColorBySign != null && textColorBySign !== 0) {
        textColor = textColorBySign > 0 ? colors.positive : colors.negative;
    }

    return (
        <StyledCard>
            <CardTitle>
                {label}
            </CardTitle>
            {!isLoading
                ? (
                    <>
                        <CardSubtitle color={textColor}>
                            {value}
                        </CardSubtitle>
                        {percentage}
                    </>
                )
                : (
                    <div>
                        Loading...
                    </div>
                )
            }
        </StyledCard>
    );
};

export default PortfolioCard;
