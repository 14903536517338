import Register from 'components/pages/account/Register';
import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoadingScreen } from 'components/atoms/loadingScreen';
import LoginWithUserName from 'components/pages/account/LoginWithUserName';
import Dashboard from 'components/pages/user/Dashboard';
import { useSession } from 'contexts/sessionContext';
import { Right } from 'utils/constants';

// Templates
const Account = lazy(() => import('components/templates/Account'));
const Default = lazy(() => import('components/templates/Default'));
const Admin = lazy(() => import('components/templates/Admin'));
const User = lazy(() => import('components/templates/User'));
const RegisterInformation = lazy(() => import('components/pages/account/RegisterInformation'));
const Tfa = lazy(() => import('components/pages/account/Tfa'));

// Account pages
const ForgotPassword = lazy(() => import('components/pages/account/ForgotPassword'));
const ResetPassword = lazy(() => import('components/pages/account/ResetPassword'));
const Login = lazy(() => import('components/pages/account/Login'));

// Admin pages
const AdminDashboard = lazy(() => import('components/pages/admin/Dashboard'));
const AdminUserOverview = lazy(() => import('components/pages/admin/UserOverview'));
const AdminProfile = lazy(() => import('components/pages/admin/Profile'));
const EditFaq = lazy(() => import('components/pages/admin/EditFaq'));
const AdminTransactionOverview = lazy(() => import('components/pages/admin/TransactionOverview'));
const Packages = lazy(() => import('components/pages/admin/Packages'));

// User pages
const UserProfile = lazy(() => import('components/pages/user/Profile'));
const TransactionOverview = lazy(() => import('components/pages/user/TransactionOverview'));
const Portfolio = lazy(() => import('components/pages/user/Portfolio'));
const TaxRapports = lazy(() => import('components/pages/user/TaxRapports'));
const Faq = lazy(() => import('components/pages/user/Faq'));
const Support = lazy(() => import('components/pages/user/Support'));
const Disclaimer = lazy(() => import('components/pages/user/Disclaimer'));

const AppRoutes = () => {
    const { hasRight } = useSession();

    return (
        <Suspense fallback={<LoadingScreen />}>
            <Routes>
                <Route path="account" element={<Account />}>
                    <Route index element={<Login />} />
                    {process.env.NODE_ENV === 'development' && (
                        <Route
                            path="loginwithusername/:username"
                            element={<LoginWithUserName />}
                        />
                    )}
                    <Route
                        path="forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route
                        path="register/:invitationCode"
                        element={<Register />}
                    />
                    <Route path="tfa" element={<Tfa />} />
                    <Route path="register" element={<RegisterInformation />} />
                </Route>

                <Route path="/" element={<Default />}></Route>

                <Route path="admin" element={<Admin />}>
                    <Route
                        index
                        element={<Navigate replace to="dashboard" />}
                    />
                    <Route path="profile" element={<AdminProfile />} />
                    <Route path="dashboard" element={<AdminDashboard />} />
                    <Route
                        path="user-overview"
                        element={<AdminUserOverview />}
                    />
                    <Route
                        path="packages"
                        element={<Packages />}
                    />
                    <Route path="edit-faq" element={<EditFaq />} />
                    <Route
                        path="edit-transactions/:userId"
                        element={<AdminTransactionOverview />}
                    />
                </Route>

                <Route path="user" element={<User />}>
                    <Route
                        index
                        element={<Navigate replace to={hasRight(Right.DashboardEnabled) ? 'dashboard' : 'portfolio'} />}
                    />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="portfolio" element={<Portfolio />} />
                    <Route path="disclaimer" element={<Disclaimer />} />
                    <Route
                        path="transaction-overview"
                        element={<TransactionOverview />}
                    />
                    <Route
                        path="transaction-overview/:coinId"
                        element={<TransactionOverview />}
                    />
                    <Route path="profile" element={<UserProfile />} />
                    <Route path="tax-rapports" element={<TaxRapports />} />
                    <Route path="faq" element={<Faq />} />
                    <Route path="support" element={<Support />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
