import styled from '@emotion/styled';

export const CardWrapper = styled.div`
    display: flex;
    margin-bottom: 1rem;
`;

export const Sidebar = styled.div`
    background-color: ${({ theme }) => theme.colors.medium} ;
    min-width: 20rem;
    padding: 2rem 1.5rem;
`;

export const DashboardWrapper = styled.div`
    display: flex;
    min-height: 100%;
    overflow-x: auto;
`;

export const DashboardWrapperLeft = styled.div`
    min-width: 60rem;
    flex-grow: 1;
    padding: 2rem 1.5rem;
    height: 100%;
`;

export const MaxWidthContainer = styled.div`
    max-width: 90rem;
    margin: auto;
`;

export const GraphsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    height: 100%;
`;

export const GraphWrapper = styled.div<{ margin?: boolean }>`
    width: calc(50% - 1rem);
    margin-left: ${props => props.margin ? '1rem' : '0'};
    margin-bottom: 1rem;
`;
