export interface Colors {
    primary: string;
    secondary: string;
    success: string;
    error: string;
    errorFaded: string;
    dark: string;
    white: string;
    black: string;
    light: string;
    medium: string;
    grey: string;
    border: string;
    transparent: string;
    positive: string;
    negative: string;
}

export const colors: Colors = {
    primary: '#ffa500',
    secondary: '#EB9532',
    success: '#51B379',
    error: '#DD524C',
    errorFaded: 'rgba(221, 82, 76, .1)',
    dark: '#34173D',
    white: '#ffffff',
    black: '#282828',
    light: '#F6F6F6',
    medium: '#F0F0F0',
    grey: '#C9C9C9',
    border: '#F3F3F3',
    transparent: 'transparent',
    positive: '#3DD365',
    negative: '#D33D3D'
};

export const chartColors: string[] = [
    '#FFB703',
    '#126782',
    '#FD9E02',
    '#219EBC',
    '#FB8500',
    '#8ECAE6',
    '#282828'
];
