import { useQuery } from 'react-query';
import { CustomError, useApi } from '..';
import { BalanceModel, PortfolioSettingsModel } from './types';

const usePortfolio = () => {
    const { apiGet, apiPut } = useApi();

    const baseUrl = '/Portfolio';

    const useBalances = () => {
        const queryKey = ['portfolio.balances', {}];

        return useQuery<BalanceModel[], CustomError[]>(queryKey, async () => {
            const response = await balances();

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const balances = async () => {
        const response = await apiGet<BalanceModel[]>(`${baseUrl}/balances`);
        return response;
    };

    const taxRapport = async () => {
        const response = await apiGet(`${baseUrl}/taxRapport`);
        return response;
    };

    const settings = async () => {
        const response = await apiGet<PortfolioSettingsModel>(`${baseUrl}/settings`);
        return response;
    };

    const useSettings = () => {
        const queryKey = ['portfolio.settings'];

        return useQuery<PortfolioSettingsModel | null, CustomError[]>(queryKey, async () => {
            const response = await settings();

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? null;
        });
    };

    const editSettings = async (model: PortfolioSettingsModel) => {
        const response = await apiPut(`${baseUrl}/settings`, model);
        return response;
    };

    return {
        useBalances,
        taxRapport,
        settings,
        editSettings,
        useSettings
    };
};

export default usePortfolio;



