import { BalanceModel } from 'hooks/api/portfolio/types';

export const stats = (balances: BalanceModel[]) => {
    const totalBalance = balances
        .map(balance => balance.coinPricing ? balance.coinPricing.price * balance.holdings : 0)
        .reduce((a, b) => a + b, 0);

    const totalCosts = balances
        .map(balance => balance.costBasis)
        .reduce((a, b) => a + b, 0);

    const totalProfitLoss = totalBalance - totalCosts;

    const totalProfitLossPercentage = (totalProfitLoss / totalCosts) * 100;

    return {
        totalBalance,
        totalCosts,
        totalProfitLoss,
        totalProfitLossPercentage
    };
};
