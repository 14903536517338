import { FC, useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { toFormikErrors } from 'utils/errorhelper';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FormGroup, FormikFormControl } from 'components/atoms/form';
import { Button } from 'components/atoms/button';
import { InvitationUserInfoModel } from 'hooks/account/types';
import FormWrapper from 'components/atoms/form/FormWrapper';
import { SubText, TitleText } from 'components/atoms/text/styles';
import { FooterLink } from 'components/atoms/footerLink';
import { LoadingScreen } from 'components/atoms/loadingScreen';
import useUserInvitation from 'hooks/api/userInvitation';
import useUser from 'hooks/api/user';
import { CompleteUserInvitationInputModel } from 'hooks/api/user/types';
import { Card } from 'components/atoms/card';

const Register: FC = () => {
    const { t } = useTranslation('register');
    const { invitationCode } = useParams();
    const { invitationUserInfo } = useUserInvitation();
    const { completeInvitation } = useUser();
    const [retrievedUserInfo, setRetrievedUserInfo] = useState<InvitationUserInfoModel>();
    const [registered, setRegistered] = useState(false);

    const onSubmit = async (values: CompleteUserInvitationInputModel, { setSubmitting, setErrors }: FormikHelpers<CompleteUserInvitationInputModel>) => {
        const response = await completeInvitation(values);
        if (!response.ok && response.errors) {
            const errors = toFormikErrors<CompleteUserInvitationInputModel>(response.errors);
            setErrors(errors);
        } else {
            setRegistered(true);
        }

        setSubmitting(false);
    };

    const getInvitationUserInfo = async (invitationCode: string) => {
        const response = await invitationUserInfo(invitationCode);
        setRetrievedUserInfo(response.data);
    };

    useEffect(() => {
        if (invitationCode) {
            getInvitationUserInfo(invitationCode);
        }
    }, []);

    const initialValues: CompleteUserInvitationInputModel = {
        id: retrievedUserInfo?.userId ?? '',
        firstName: '',
        insertion: '',
        lastName: '',
        email: retrievedUserInfo?.email ?? '',
        password: '',
        passwordConfirm: '',
        invitationCode: invitationCode ?? '',
    };

    const successCard = (
        <Card>
            <TitleText>
                {t('succesfullyRegistered')}
            </TitleText>
            <SubText>
                {t('youCanLogin')}
            </SubText>
            <FooterLink style={{ marginTop: '1rem' }} to="/account" center>
                {t('backToLogin')}
            </FooterLink>
        </Card>
    );

    const noInvitationCard = (
        <Card>
            <TitleText>
                {t('noInvitationCode')}
            </TitleText>
            <FooterLink style={{ marginTop: '1rem' }} to="/account" center>
                {t('backToLogin')}
            </FooterLink>
        </Card>
    );

    if (!invitationCode) {
        return noInvitationCard;
    }

    if (retrievedUserInfo == null) {
        return <LoadingScreen />;
    }

    if (registered) {
        return successCard;
    }

    return (
        <Card>
            <TitleText>
                {t('register')}
            </TitleText>
            <SubText>
                {t('fillIn')}
            </SubText>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ isSubmitting, isValid }) => (
                    <FormWrapper>
                        <Form>
                            <FormGroup label={t('firstName')} required>
                                <FormikFormControl name="firstName" placeholder={t('firstName')} />
                            </FormGroup>
                            <FormGroup label={t('insertion')} required>
                                <FormikFormControl name="insertion" placeholder={t('insertion')} />
                            </FormGroup>
                            <FormGroup label={t('lastName')} required>
                                <FormikFormControl name="lastName" placeholder={t('lastName')} />
                            </FormGroup>
                            <FormGroup label={t('common:mail')} required>
                                <FormikFormControl name="email" placeholder={t('common:mail')} />
                            </FormGroup>
                            <FormGroup label={t('common:password')} required>
                                <FormikFormControl name="password" type="password" placeholder={t('common:password')} />
                            </FormGroup>
                            <FormGroup label={t('passwordConfirm')} required>
                                <FormikFormControl name="passwordConfirm" type="password" placeholder={t('passwordConfirm')} />
                            </FormGroup>
                            <Button style={{ marginTop: '2rem' }} type="submit" disabled={!isValid} loading={isSubmitting}>
                                {t('register')}
                            </Button>
                        </Form>
                    </FormWrapper>
                )}
            </Formik>
        </Card>
    );
};

export default Register;
