import React, { FC } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { FormGroupProps } from 'react-bootstrap/esm/FormGroup';

interface CustomFormGroupProps extends FormGroupProps {
    label?: string;
    required?: boolean;
    text?: string;
}

const FormGroup: FC<CustomFormGroupProps> = ({ label, required, text, style, children, ...inputProps }) => {
    return (
        <Form.Group style={{ marginBottom: '1rem', ...style }} {...inputProps}>
            {
                label && label.length > 0 &&
                <Form.Label>
                    {label}
                </Form.Label>
            }
            {
                text && text.length > 0 &&
                <Form.Text>
                    {text}
                </Form.Text>
            }
            {children}
        </Form.Group>
    );
};

export default FormGroup;
