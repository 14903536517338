import styled from '@emotion/styled';
import { Button } from 'components/atoms/button';
import Card from 'react-bootstrap/Card';

export const Popup = styled(Card)`
    position: absolute;
    height: 10rem;
    width: 30rem;
    z-index: 20;
    padding: 1rem;
    border-radius: 1rem;
    text-align: center;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 2rem;
`;

export const ConfirmButton = styled(Button)`
    color: rgb(255, 255, 255);
    background-color: rgb(220, 60, 60);
`;
