import styled from '@emotion/styled';

export const TitleText = styled.h3`
  font-size: 1.7rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
`;

export const SubText = styled.p`
  font-size: 1.1rem;
  color: rgb(160, 160, 160);
`;
