import styled from '@emotion/styled';

const InvalidFeedback = styled.span`
  display: block;
  font-size: 0.875rem;
  line-height: 1rem;
  margin-top: 0.5rem;
  color: rgb(150, 20, 20);
`;

export default InvalidFeedback;
