import useGraph from 'hooks/api/graph';
import { PeriodType } from 'hooks/api/graph/types';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LineGraph, { LineGraphDataset } from '..';

const LineGraphProfitLoss: FC = () => {
    const { t } = useTranslation('lineGraph');
    const [periodType, setPeriodType] = useState<PeriodType>(PeriodType.Month);

    const { useLineGraphProfitLoss } = useGraph();
    const { data = [], isLoading } = useLineGraphProfitLoss(periodType);

    const graphData = useMemo(() => {
        if (data == null) {
            return {
                labels: [],
                datasets: []
            };
        }

        const labels = data.map(m => ({ label: m.name, abbreviation: m.abbreviation }));
        const datasets: LineGraphDataset[] = [
            {
                label: t('label.lineGraphProfitLoss'),
                data: data.map(m => m.profitLoss),
                color: '#2596be'
            }
        ];

        return {
            labels,
            datasets
        };
    }, [data]);

    return (
        <LineGraph
            type="percentage"
            title={t('label.lineGraphProfitLoss')}
            periodType={periodType}
            setPeriodType={setPeriodType}
            isLoading={isLoading}
            {...graphData}
        />
    );
};

export default LineGraphProfitLoss;
