import styled from '@emotion/styled';

export const StyledCard = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    padding: 1rem;
`;

export const CardTitle = styled.h6`
    color: ${({ theme }) => theme.colors.grey};
`;

export const CardSubtitle = styled.h4<{ color?: string }>`
    color: ${({ theme, color }) => color || theme.colors.black};
    font-size: 1.375rem;
`;
