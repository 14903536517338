import useGraph from 'hooks/api/graph';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CircleGraph from '..';

const CircleGraphCost: FC = () => {
    const { t } = useTranslation('circleGraph');

    const { useCircleGraphCost } = useGraph();
    const { data = null, isLoading } = useCircleGraphCost();

    const graphData = useMemo(() => {
        if (data == null) {
            return {
                nameLabels: [],
                symbolLabels: [],
                values: [],
                percentageValues: []
            };
        }

        const nameLabels = [
            ...data.shownShares.map((share) => share.coin.name),
            ...(data.othersSharePercentage != null ? [t('label.others')] : [])
        ];

        const symbolLabels = [
            ...data.shownShares.map((share) => share.coin.symbol),
            ...(data.othersSharePercentage != null ? [t('label.others')] : [])
        ];

        const values = [
            ...data.shownShares.map((share) => share.cost),
            ...(data.othersShareCost != null ? [data.othersShareCost] : [])
        ];

        const percentageValues = [
            ...data.shownShares.map((share) => share.percentage),
            ...(data.othersSharePercentage != null ? [data.othersSharePercentage] : [])
        ];

        return {
            nameLabels,
            symbolLabels,
            values,
            percentageValues
        };
    }, [data]);

    return (
        <CircleGraph title={t('label.circleGraphCost')} isLoading={isLoading} {...graphData} />
    );
};

export default CircleGraphCost;
