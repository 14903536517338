import { FC, PropsWithChildren, useEffect } from 'react';
import { PopupCard } from './style';

export interface ExpandedComponentProps {
    close: () => void;
}

const ExpandedComponent: FC<PropsWithChildren<ExpandedComponentProps>> = ({ close, children }) => {
    useEffect(() => {
        if (close != null) {
            const handleEsc = (event: KeyboardEvent) => {
                if (event.key === 'Escape') {
                    close();
                    window.removeEventListener('keydown', handleEsc);
                }
            };

            window.addEventListener('keydown', handleEsc);

            return () => {
                window.removeEventListener('keydown', handleEsc);
            };
        }
    }, [close]);

    return (
        <>
            <PopupCard aspectratio={5 / 7}>
                {children}
            </PopupCard>
        </>
    );
};

export default ExpandedComponent;
