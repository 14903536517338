import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Dutch files
import commonNl from './common/nl.json';
import loginNl from 'components/pages/account/Login/translations/nl.json';
import forgotPasswordNl from 'components/pages/account/ForgotPassword/translations/nl.json';
import resetPasswordNl from 'components/pages/account/ResetPassword/translations/nl.json';
import registerNl from 'components/pages/account/Register/translations/nl.json';
import adminDashboardNl from 'components/pages/admin/Dashboard/translations/nl.json';
import adminInviteUsersNl from 'components/pages/admin/InviteUsers/translations/nl.json';
import userOverviewNl from 'components/pages/admin/UserOverview/translations/nl.json';
import adminNl from 'components/templates/Admin/translations/nl.json';
import layoutNl from 'components/organisms/Layout/translations/nl.json';
import userNl from 'components/templates/User/translations/nl.json';
import transactionsOverviewNl from 'components/pages/user/TransactionOverview/translations/nl.json';
import editTransactionNl from 'components/organisms/EditTransaction/translations/nl.json';
import confirmPopupNl from 'components/atoms/confirmPopup/ConfirmPopup/translations/nl.json';
import editUserNl from 'components/pages/admin/EditUser/translations/nl.json';
import adminProfileNl from 'components/pages/admin/Profile/translations/nl.json';
import userProfileNl from 'components/pages/user/Profile/translations/nl.json';
import fileUploadPopupNl from 'components/atoms/fileUploadPopup/FileUploadPopup/translations/nl.json';
import portfolioNl from 'components/pages/user/Portfolio/translations/nl.json';
import registerInformationNl from 'components/pages/account/RegisterInformation/translations/nl.json';
import coinFilterNl from 'components/organisms/CoinFilter/translations/nl.json';
import taxRapportsNl from 'components/pages/user/TaxRapports/translations/nl.json';
import editFaqNl from 'components/pages/admin/EditFaq/translations/nl.json';
import faqNl from 'components/pages/user/Faq/translations/nl.json';
import supportNl from 'components/pages/user/Support/translations/nl.json';
import portfolioSettingsNl from 'components/pages/user/Portfolio/PortfolioSettings/translations/nl.json';
import tfaNl from 'components/pages/account/Tfa/translations/nl.json';
import disclaimerNl from 'components/pages/user/Disclaimer/translations/nl.json';
import circleGraphNl from 'components/organisms/graphs/CircleGraph/translations/nl.json';
import lineGraphNl from 'components/organisms/graphs/LineGraph/translations/nl.json';
import userDashboardNl from 'components/pages/user/Dashboard/translations/nl.json';
import risersFallersNl from 'components/organisms/RisersFallers/translations/nl.json';
import loadingNl from 'components/atoms/loadingScreen/translations/nl.json';
import packagesNl from 'components/pages/admin/Packages/translations/nl.json';
import editPackageNl from 'components/organisms/EditPackage/translations/nl.json';
import packageLimitPopupNl from 'components/atoms/packageLimitPopup/PackageLimitPopup/translations/nl.json';

const resources = {
    nl: {
        common: commonNl,
        login: loginNl,
        forgotPassword: forgotPasswordNl,
        resetPassword: resetPasswordNl,
        register: registerNl,
        adminDashboard: adminDashboardNl,
        adminInviteUsers: adminInviteUsersNl,
        userOverview: userOverviewNl,
        editUser: editUserNl,
        admin: adminNl,
        layout: layoutNl,
        adminProfile: adminProfileNl,
        userProfile: userProfileNl,
        user: userNl,
        transactionsOverview: transactionsOverviewNl,
        editTransaction: editTransactionNl,
        confirmPopup: confirmPopupNl,
        fileUploadPopup: fileUploadPopupNl,
        portfolio: portfolioNl,
        registerInformation: registerInformationNl,
        coinFilter: coinFilterNl,
        taxRapports: taxRapportsNl,
        editFaq: editFaqNl,
        faq: faqNl,
        support: supportNl,
        portfolioSettings: portfolioSettingsNl,
        tfa: tfaNl,
        disclaimer: disclaimerNl,
        circleGraph: circleGraphNl,
        lineGraph: lineGraphNl,
        userDashboard: userDashboardNl,
        risersFallers: risersFallersNl,
        loading: loadingNl,
        packages: packagesNl,
        editPackage: editPackageNl,
        packageLimitPopup: packageLimitPopupNl
    }
};

const ns = Object.keys(resources.nl);

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'nl',
        fallbackLng: 'nl',
        supportedLngs: ['nl'],
        debug: process.env.NODE_ENV === 'development',
        ns,
        defaultNS: 'common',

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
