import { CoinModel } from '../coin/types';

export interface GainersLosersInputModel {
    range: GainersLosersRange;
    gainers: boolean;
}

export enum GainersLosersRange {
    Hour = 0,
    Day = 1,
    Week = 2
}

export interface GainerLoserModel {
    coin: CoinModel;
    percentage: number;
}
