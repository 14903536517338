import React, { FC } from 'react';
import { Wrapper } from './styles';

const FormWrapper: FC = ({ children, ...inputProps }) => {
    return (
        <Wrapper {...inputProps}>
            {children}
        </Wrapper>
    );
};

export default FormWrapper;
