import styled from '@emotion/styled';
import Form from 'react-bootstrap/esm/Form';

export const SmallCheckbox = styled(Form.Check)`
  font-size: 0.8rem;
`;

export const BigCheckbox = styled(Form.Check)`
  font-size: 1rem;
`;
