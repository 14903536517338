import styled from '@emotion/styled';
import Button from 'react-bootstrap/Button';

export const CustomButton = styled<any>(Button)`
  background-color: ${({ theme }) => theme.colors.black};
  border: none;
  height: 2.5rem;
  width: 100%;
  border-radius: 0.5rem;
  color: white;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
`;
