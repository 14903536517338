import styled from '@emotion/styled';
import Card from 'react-bootstrap/Card';

export const StyledCard = styled(Card) <{ variant?: 'small', zindex?: number }>`
  border-width: 0;
  width:  ${({ variant }) => variant === 'small' ? '100%' : '33rem'};
  border-radius: ${({ variant }) => variant === 'small' ? '1rem' : '1.8rem'};
  padding: ${({ variant }) => variant === 'small' ? '0' : '2.2rem'};
  position: ${({ variant }) => variant === 'small' ? 'relative' : 'absolute'};
  z-index: ${({ variant, zindex }) => variant === 'small' ? 'initial' : zindex ?? 20};
  max-height: 90vh;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.white};
`;
