import { css } from '@emotion/react';
import styled from '@emotion/styled';
import FormToggle from 'components/atoms/form/FormToggle';
import Select from 'react-select';

export const TopBar = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space.gap.s};
`;

export const Toggle = styled(FormToggle)`
    margin-bottom: ${({ theme }) => theme.space.gap.m};
    span {
        font-size: .625rem;
    }
`;

export const Title = styled.h6`
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.grey};
`;

export const SelectWrapper = styled(Select)`
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 0 .75rem;
    border-radius: 3.125rem;
    font-size: .75rem;
`;

export const TabSwitch = styled.div`
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.light};
    width: 100%;
    border-radius: 3.125rem;
    padding: .1875rem;
    font-weight: 500;
`;

export const IconWrapper = styled.div<{ active?: boolean }>`
    display: flex;
    align-items: center;
    padding: .5rem .75rem;
    border-radius: 3.125rem;
    width: 50%;
    justify-content: center;
    color: ${({ theme }) => theme.colors.grey};
    font-size: .75rem;
    line-height: 1.2;
    &:hover {
        background-color: ${({ theme }) => theme.colors.light};
        cursor: pointer;
    }

    svg {
        margin-right: .375rem;

        > * {
            transition: all ease 1s;
            fill: ${({ theme }) => theme.colors.grey};
        }
    }

    ${({ active, theme }) => active &&
        css`
          background-color: ${theme.colors.light};
          color: ${theme.colors.dark};

           svg > * {
            fill: ${theme.colors.positive};
           }
        `
    }
`;

export const NoData = styled.div`
    text-align: center;
    font-size: .8rem;
    margin-top: 1rem;
`;
