import { PopupProps } from 'components/organisms/PopupOverlay/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup, ButtonsWrapper, ConfirmButton } from './styles';
import { Button } from 'components/atoms/button';

export interface PackageLimitPopupProps extends PopupProps {
    text: string;
    isAdmin?: boolean;
}

const PackageLimitPopup: FC<PackageLimitPopupProps> = ({ text, isAdmin, close }) => {
    const { t } = useTranslation('packageLimitPopup');

    const confirm = async () => {
        window.open('https://onthechain.nl/product/beheer-je-crypto-portfolio-applicatie/', '_blank')?.focus();
        close();
    };

    return (
        <Popup>
            <>
                {text}
                <ButtonsWrapper>
                    {!isAdmin &&
                        <ConfirmButton onClick={confirm}>
                            {t('toPackages')}
                        </ConfirmButton>
                    }
                    <Button
                        variant="dark"
                        onClick={() => close(true)}
                        style={{ marginLeft: isAdmin ? 0 : '1rem' }}
                    >
                        {t('common:cancel')}
                    </Button>
                </ButtonsWrapper>
            </>
        </Popup>
    );
};

export default PackageLimitPopup;
