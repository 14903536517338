import { InvitationUserInfoModel } from 'hooks/account/types';
import { useApi } from '..';

const useUserInvitation = () => {
    const { apiGet } = useApi();

    const baseUrl = '/UserInvitation';

    const invitationUserInfo = async (invitationCode: string) => {
        const response = await apiGet<InvitationUserInfoModel>(`${baseUrl}/invitationUserInfo/${invitationCode}`);
        return response;
    };

    return {
        invitationUserInfo
    };
};

export default useUserInvitation;
