import { FC, InputHTMLAttributes } from 'react';
import { ToggleContainer, HiddenCheckbox, Label, Wrapper } from './styles';
import InvalidFeedback from '../InvalidFeedback';

export interface FormToggleProps {
    label?: string;
    className?: string;
    disabled?: boolean;
    error?: string;
}

const FormToggle: FC<InputHTMLAttributes<HTMLInputElement> & FormToggleProps> = ({ className, label, checked, error, disabled, ...props }) => {
    return (
        <>
            <Wrapper label={label} className={className} disabled={disabled}>
                <ToggleContainer>
                    <HiddenCheckbox disabled={disabled} type="checkbox" checked={checked} {...props} />
                    <div></div>
                </ToggleContainer>
                {label && <Label>
                    {label}
                </Label>}
            </Wrapper>
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormToggle;
