import { Field } from 'formik';
import { FC, InputHTMLAttributes } from 'react';
import Form from 'react-bootstrap/esm/Form';
import InvalidFeedback from '../InvalidFeedback';

export interface FormControlProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: string;
}

const FormControl: FC<FormControlProps> = ({ error, ...inputProps }) => {
    return (
        <>
            <Field as={Form.Control} {...inputProps} />
            {error && <InvalidFeedback>{error}</InvalidFeedback>}
        </>
    );
};

export default FormControl;
