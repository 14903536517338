import { FC, useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, ChartOptions } from 'chart.js';
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';
import { InsideCircleBlock, InsideCirclePercentage, InsideCircleTitle, InsideCircleTitleWrapper, InsideCircleWrapper, LegendItemColor, LegendItemPercentage, LegendItemTitle, LegendItemWrapper, LegendWrapper } from './styles';
import { Col, Row } from 'react-bootstrap';
import { chartColors } from 'theme/colors';
import Icon from 'components/atoms/Icon';
import { useOutletContext } from 'react-router';
import { OutletContext } from 'components/organisms/Layout/types';
import ExpandedComponent from 'components/atoms/expandedComponent';
import { ExpandButton, GraphWrapper, Title, Wrapper } from '../styles';
import { useTranslation } from 'react-i18next';
import { useSession } from 'contexts/sessionContext';


ChartJS.register(ArcElement, Tooltip, Legend);

interface CircleGraphProps {
    title: string;
    nameLabels: string[];
    symbolLabels: string[];
    values: number[];
    percentageValues: number[];
    expanded?: boolean;
    close?: () => void;
    isLoading?: boolean;
}

const CircleGraph: FC<CircleGraphProps> = (props) => {
    const {
        expanded,
        title,
        nameLabels,
        symbolLabels,
        values,
        percentageValues,
        close,
        isLoading
    } = props;

    const { t } = useTranslation('circleGraph');
    const chartRef = useRef();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const outletContext = useOutletContext<OutletContext>();

    const { session } = useSession();


    useEffect(() => {
        if (!expanded) {
            outletContext.setPopups([{
                name: `expand-${title}`,
                element: (close) => (
                    <ExpandedComponent close={close}>
                        <CircleGraph close={close} expanded={true} {...props} />
                    </ExpandedComponent>
                )
            }]);
        }
    }, [isLoading, session]);


    if (values.length === 0 || isLoading) {
        return (
            <Wrapper expanded={false}>
                <Row>
                    <Col>
                        <Title>
                            {title}
                        </Title>
                    </Col>
                </Row>
                <Row style={{ padding: '1rem' }}>
                    {t(isLoading ? 'common:loading' : 'noData').toString()}
                </Row>
            </Wrapper>
        );
    }

    const data: ChartData<'doughnut', number[], string> = {
        labels: nameLabels,
        datasets: [
            {
                label: title,
                data: percentageValues,
                backgroundColor: chartColors,
                borderWidth: 0
            },
        ]
    };

    const options: ChartOptions<'doughnut'> = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        },
        cutout: '70%',
        maintainAspectRatio: false
    };

    const asSharePercentage = (percentage: number) => {
        if (percentage < 0.01) {
            return '>0.00%';
        }

        const percentageString = percentage.toLocaleString('nl-NL', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return `${percentageString}%`;
    };

    const formattedValues = values.map((v) => v.toLocaleString('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }));

    const percentages = percentageValues.map((p) => asSharePercentage(p * 100));

    const onHover = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        if (chartRef.current != null) {
            const element = getElementAtEvent(chartRef.current, e);
            if (element.length > 0) {
                setSelectedIndex(element[0].index);
            }
        }
    };

    return (
        <Wrapper expanded={expanded ?? false}>
            <Row>
                <Col md={9}>
                    <Title>
                        {title}
                    </Title>
                </Col>
                <Col md={3}>
                    {expanded && close != null ?
                        <ExpandButton onClick={close}>
                            <Icon name="collapse" />
                        </ExpandButton> :
                        <ExpandButton onClick={() => outletContext.openPopup(`expand-${title}`)}>
                            <Icon name="expand" />
                        </ExpandButton>
                    }
                </Col>
            </Row>
            <Row style={{ marginTop: '2rem' }}>
                <Col style={{ width: '60%', maxWidth: expanded === true ? '35rem' : '25rem' }}>
                    <GraphWrapper expanded={expanded ?? false}>
                        <Doughnut ref={chartRef} data={data} options={options} onMouseMove={onHover} onClick={onHover} />
                        <InsideCircleWrapper expanded={expanded ?? false}>
                            <InsideCircleBlock>
                                <InsideCircleTitleWrapper>
                                    <LegendItemColor color={chartColors[selectedIndex]} />
                                    <InsideCircleTitle>
                                        {nameLabels[selectedIndex]} {expanded && `(${symbolLabels[selectedIndex]})`}
                                    </InsideCircleTitle>
                                </InsideCircleTitleWrapper>
                                {expanded &&
                                    <InsideCirclePercentage>
                                        €{formattedValues[selectedIndex]}
                                    </InsideCirclePercentage>
                                }
                                <InsideCirclePercentage>
                                    {percentages[selectedIndex]}
                                </InsideCirclePercentage>
                            </InsideCircleBlock>
                        </InsideCircleWrapper>
                    </GraphWrapper>
                </Col>
                <LegendWrapper expanded={expanded}>
                    {values.map((_, index) => (
                        <LegendItemWrapper
                            key={index}
                            onClick={() => setSelectedIndex(index)}
                            onMouseMove={() => setSelectedIndex(index)}
                        >
                            <LegendItemColor color={chartColors[index]} />
                            <LegendItemTitle>
                                {expanded ? `${nameLabels[index]} (${symbolLabels[index]})` : symbolLabels[index]}
                            </LegendItemTitle>
                            <LegendItemPercentage>
                                {expanded ? `(€${formattedValues[index]} / ${percentages[index]})` : `(${percentages[index]})`}
                            </LegendItemPercentage>
                        </LegendItemWrapper>
                    ))}
                </LegendWrapper>
            </Row>
        </Wrapper>
    );
};

export default CircleGraph;
