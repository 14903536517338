import { useField } from 'formik';
import React, { FC } from 'react';
import FormControl, { FormControlProps } from '..';

export interface FormikFormControlProps extends FormControlProps {
    name: string;
}

const FormikFormControl: FC<FormikFormControlProps> = (props) => {
    const [field, meta] = useField(props);
    const error = !!(meta.touched && meta.error) ? meta.error : undefined;

    return <FormControl {...field} {...props} error={error} />;
};

export default FormikFormControl;
