import useGraph from 'hooks/api/graph';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CircleGraph from '..';

const CircleGraphValue: FC = () => {
    const { t } = useTranslation('circleGraph');

    const { useCircleGraphValue } = useGraph();
    const { data = null, isLoading } = useCircleGraphValue();

    const graphData = useMemo(() => {
        if (data == null) {
            return {
                nameLabels: [],
                symbolLabels: [],
                values: [],
                percentageValues: []
            };
        }

        const nameLabels = [
            ...data.shownShares.map((share) => share.coin.name),
            ...(data.othersSharePercentage != null ? [t('label.others')] : [])
        ];

        const symbolLabels = [
            ...data.shownShares.map((share) => share.coin.symbol),
            ...(data.othersSharePercentage != null ? [t('label.others')] : [])
        ];

        const values = [
            ...data.shownShares.map((share) => share.value),
            ...(data.othersShareValue != null ? [data.othersShareValue] : [])
        ];

        const percentageValues = [
            ...data.shownShares.map((share) => share.percentage),
            ...(data.othersSharePercentage != null ? [data.othersSharePercentage] : [])
        ];

        return {
            nameLabels,
            symbolLabels,
            values,
            percentageValues
        };
    }, [data]);

    return (
        <CircleGraph title={t('label.circleGraphValue')} isLoading={isLoading} {...graphData} />
    );
};

export default CircleGraphValue;
