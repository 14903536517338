import { useQuery } from 'react-query';
import { CustomError, useApi } from '..';
import { InviteMultiInputModel } from '../account/types';
import { BlockUserInputModel, CompleteUserInvitationInputModel, EditUserInputModel, EditUserProfileInputModel, SearchUserInputModel, UserModel } from './types';

const useUser = () => {
    const { apiGet, apiPost, apiPut } = useApi();

    const baseUrl = '/User';

    const useSearch = (params: SearchUserInputModel) => {
        const queryKey = ['user.search', params];

        return useQuery<UserModel[], CustomError[]>(queryKey, async () => {
            const response = await search(params);

            if (!response.ok && response.errors != null) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    const search = async (model: SearchUserInputModel) => {
        const response = await apiPost<UserModel[]>(`${baseUrl}/search`, model);
        return response;
    };

    const edit = async (model: EditUserInputModel) => {
        const response = await apiPut(`${baseUrl}/`, model);
        return response;
    };

    const editProfile = async (model: EditUserProfileInputModel) => {
        const response = await apiPut(`${baseUrl}/profile`, model);
        return response;
    };

    const block = async (model: BlockUserInputModel) => {
        const response = await apiPut(`${baseUrl}/block/`, model);
        return response;
    };

    const inviteMulti = async (model: InviteMultiInputModel) => {
        const response = await apiPost(`${baseUrl}/inviteMulti`, model);
        return response;
    };

    const completeInvitation = async (model: CompleteUserInvitationInputModel) => {
        const response = await apiPost(`${baseUrl}/invite/complete`, model);
        return response;
    };

    const exportUserRapport = async () => {
        const response = await apiGet(`${baseUrl}/exportUserRapport`);
        return response;
    };

    return {
        search,
        edit,
        editProfile,
        inviteMulti,
        completeInvitation,
        useSearch,
        block,
        exportUserRapport
    };
};

export default useUser;
