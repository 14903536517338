import { useQuery } from 'react-query';
import { CustomError, useApi } from '..';
import { GainerLoserModel, GainersLosersInputModel } from './types';

const useTrends = () => {
    const { apiPost } = useApi();

    const baseUrl = '/Trends';

    const gainersLosers = async (model: GainersLosersInputModel) => {
        const response = await apiPost<GainerLoserModel[]>(`${baseUrl}/gainersLosers`, model);
        return response;
    };

    const useGainersLosers = (model: GainersLosersInputModel) => {
        const queryKey = ['gainersLosers', model];

        return useQuery<GainerLoserModel[], CustomError[]>(queryKey, async () => {
            const response = await gainersLosers(model);

            if (!response.ok && response.errors) {
                throw response.errors;
            }

            return response.data ?? [];
        });
    };

    return {
        useGainersLosers
    };
};

export default useTrends;
